import React from 'react';
import './App.css';
import { gsap } from "gsap";
import GlobleMap from "./media/world.svg";
import { ScrollTrigger } from 'gsap/all';
import AhauLogo from "./media/ahau-logo-white.svg";
import MotherTree from "./media/mother-tree-logo-white.svg";
import Roostr from "./media/roostr-logo-white.svg";
import Romke01 from "./media/romke-01.jpg";
const { useLayoutEffect, useRef } = React;

function App() {
  const app = useRef();
  const tl = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .from(".hdr-row-1", {
          x: 100,
          delay: 1,
          opacity: 0
        })
        .from(".hdr-row-2", {
          delay: 2,
          x: -100,
          opacity: 0
        }, "<.25")
        .from(".hdr-row-3", {
          x: -100,
          opacity: 0
        }, "<.25")
        .from(".hdr-row-4", {
          x: -100,
          opacity: 0
        }, "<.25")
        .from(".globe-map", {
          opacity: 0,
          x: -100
        }, "<.25")
        .from(".hdr-row-5", {
          opacity: 0
        });
      gsap.to(".globe-map", { rotation: "+=360", repeat: -1, duration: 8, ease: "none"});
      gsap.from(".content-intro", {scrollTrigger: { trigger: ".content-intro", start: "top center" }, duration: 3, opacity: 0}); 
      gsap.from(".brands", {scrollTrigger: { trigger: ".brands", start: "top center" }, duration: 3, opacity: 0}); 
      gsap.from(".tools", {scrollTrigger: { trigger: ".tools", start: "top center" }, duration: 3, opacity: 0}); 
      gsap.from(".publications", {scrollTrigger: { trigger: ".publications", start: "top center" }, duration: 3, opacity: 0}); 
      gsap.from(".experience", {scrollTrigger: { trigger: ".experience", start: "top center" }, duration: 3, opacity: 0}); 
      gsap.from(".impact", {scrollTrigger: { trigger: ".impact", start: "top 80%" }, duration: 3, opacity: 0}); 
      gsap.from(".creative", {scrollTrigger: { trigger: ".creative", start: "top 99%" }, duration: 3, opacity: 0}); 
    }, app);
    
    return () => ctx.revert();
  });

  return (
    
    <div ref={app} className="App">
      <div className="section">
        <div className="hdr-row-1">ROMKE DE HAAN</div>
        <div className="hdr-row-2 callout">DESIGN</div>
        <div className="hdr-row-3 callout">FOR A BETTER</div>
        <div className='row-4'><div className="hdr-row-4 callout">TOMORROW</div> <div className='globe-container'><img src ={GlobleMap} alt="Globe" className="globe-map" /></div></div>
        <div className="hdr-row-5 tagline">Multi-disciplinary designer, world-class technologist, and social impact leader.</div>
      </div>
      <div className="section white-bg">
        <div className='romke01'><img src={Romke01} alt="Romke" /></div>
        <div className='content-intro'>
          <p className='quote'>“It’s time for humanity to realign itself back into our natural world.  We have to liberate ourselves away from our wasteful and harmful ideologies and products and move into more regenerative models that help heal and sustain.”<br />-Romke</p>

          <p>Romke de Haan is a multi-disciplinary design, business, and technology practitioner, thinker with a drive to live a life of service. His mission is to help create a more equitable world for all life, especially communities that have become highly disenfranchised. He’s led multiple initiatives from his work in Government, NGOs, and enterprises transforming their work and practices to be more socially impactful and streamlined.</p>
          <p>Want to reach out?</p>
          <div className='box'><a className='contact-link' href="https://docs.google.com/forms/d/e/1FAIpQLScBCxVc2gkjX6Ndf1zPfz_2ldOOnggvMQSTZWUG14XVqh6rMQ/viewform">CONTACT</a></div>
        </div>
        <div sclassName='clear'></div>
      </div>
      <div className="section">
        <div className='experience'>
          <h2>EXPERIENCE</h2>
          <p>Romke de Haan has worked as a strategist since 1997. Over a 25 year career, Romke has helped organizations across the globe on transforming individuals and places to improve quality of life and economic growth. Romke has worked alongside organizations like the Department of Defense and the Intelligence community in delivering digital strategies in the CVE (Countering Violent Extremism) space, helping those organizations deliver mission critical impact. As a former federal employee, Romke worked with agencies like the TSA, EPA, and GSA on modernizing and solving organization issues through digital strategies.</p>
          <p>With a focus on helping NGO organizations globally, Romke has helped develop programs to transform and educate young adults away from human trafficking, gangs, CVE organization and poverty. </p>
          <p>Checkout his <a href="https://www.linkedin.com/in/romkedehaan/">LinkedIn</a> for work history.</p>
        </div>
      </div>
      <div className="section">
        <div className='brands'>
          <h2>COMPANIES</h2>
          <p>To help with his mission Romke has developed the following brands:</p>
          <div className='brand-logos'>
            <div className='logo-lockup'><img src={MotherTree} alt="Mother Tree" /></div>
            <div className='logo-lockup'><img src={Roostr} alt="Roostr" /></div>
            <div className='logo-lockup'><a href="https://ahau.community"><img src={AhauLogo} alt="ahau" /></a></div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className='tools'>
          <h2>TOOLS</h2>
          <p>Here are some tools and frameworks I've created:</p>
          <ul>
            <li><a href="https://docs.google.com/presentation/d/1tr8jpp5hIb-6r6fVyxOiD2H-Pd43UCGmWmkxILC7_Rc/edit?usp=sharing">LEAN COMMUNITY MODEL</a></li>
          </ul>
        </div>
      </div>
      <div className="section">
        <div className='publications'>
          <h2>PRESS</h2>
          <p>Here are writings published by and about Romke:</p>
          <ul>
            <li><a href='https://www.guidepointsecurity.com/blog/transforming-digital-transformation/'>Transforming Digital Transformation</a></li>
            <li><a href='https://www.uschamberfoundation.org/blog/post/overcoming-employment-barriers-facing-underserved-populations-3-lessons'>Overcoming Employment Barriers Facing Underserved Populations: 3 Lessons</a></li>
            <li><a href='https://biztimes.com/romke-de-haan/'>My Favorite Tech: Romke de Haan</a></li>
            <li><a href='https://www.milwaukeemag.com/Wunderkind/'>Wunderkind</a></li>
            <li><a href='https://commonstate.com/articles/the-makers-dozen-creative-technologist-and-entrepreneur-romke-de-haan/'>The Makers Dozen: Creative Technologist and Entrepreneur Romke de Haan</a></li>
          </ul>
        </div>
      </div>
      <div className="section">
        <div className='impact'>
          <h2>IMPACT WORK</h2>
          <p>It's so important to give back. Here are some of the organizations Romke supports:</p>
          <ul>
            <li><a href="https://www.urban-light.org/">Ubran Light Thailand</a></li>
            <li><a href="https://www.gentlemensretreat.org/">Gentlemen's Retreat</a></li>
            <li><a href="https://www.film2future.org/">Film2Future</a></li>
            <li><a href="https://www.trueskool.org/">True Skool</a></li>
          </ul>
          </div>
      </div>
      <div className='footer section'>
        <p className='footer-text'>Romke de Haan | Milwaukee, WI</p>
      </div>
  </div>
  );
}
export default App;
